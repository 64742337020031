@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.container-navbar {
  left: 0;
}
.navbar-link {
  font-size: 1.8em !important;
  margin-right: 2vw;
  font-family: "Montserrat", sans-serif;
}

.navbar-link:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.05);
}

.container {
  margin-left: 2vw !important;
}

@media (max-width: 835px) {
  .navbar-link {
    font-size: 1.1em !important;
    margin-right: 3vw;
    font-family: "Montserrat", sans-serif;
  }
}
